<template>
  <div :key="'error'" class="d-flex align-items-center mx-0">
    <div class="holder">
      <template v-for="rEr in errorList">
        <div :key="rEr[Object.keys(rEr)[0]]" class="d-flex flex-row">
          <div class="item">
            {{ Object.keys(rEr)[0] }}
          </div>
          <div class="item">
            {{ rEr[Object.keys(rEr)[0]] }}
          </div>
        </div>
      </template>
      <template v-for="key in Object.keys(lineProfile)">
        <div :key="lineProfile[key]" class="d-flex flex-row">
          <div class="item">
            {{ key }}
          </div>
          <div class="item">
            {{ lineProfile[key] }}
          </div>
        </div>
      </template>
      <template v-for="key in Object.keys(form)">
        <div :key="form[key]" class="d-flex flex-row">
          <div class="item">
            {{ key }}
          </div>
          <div class="item">
            {{ form[key] }} {{ ` ` }}{{ toUTF8Array(form[key]) }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {
  osVersion,
  osName,
  fullBrowserVersion,
  browserName,
  mobileVendor,
  mobileModel,
  engineName,
  engineVersion,
  getUA,
  deviceType
} from "mobile-device-detect";

const errorList = [
  { osVersion },
  { osName },
  { fullBrowserVersion },
  { browserName },
  { mobileVendor },
  { mobileModel },
  { engineName },
  { engineVersion },
  { getUA },
  { deviceType }
];
export default {
  props: {
    lineProfile: {
      required: false,
      type: Object,
      default: () => {
        return {};
      }
    },
    form: {
      required: false,
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data: () => {
    return {
      errorList: errorList
    };
  },
  methods: {
    toUTF8Array(str) {
      var utf8 = [];
      for (var i = 0; i < str.length; i++) {
        var charcode = str.charCodeAt(i);
        if (charcode < 0x80) utf8.push(charcode);
        else if (charcode < 0x800) {
          utf8.push(0xc0 | (charcode >> 6), 0x80 | (charcode & 0x3f));
        } else if (charcode < 0xd800 || charcode >= 0xe000) {
          utf8.push(
            0xe0 | (charcode >> 12),
            0x80 | ((charcode >> 6) & 0x3f),
            0x80 | (charcode & 0x3f)
          );
        }
        // surrogate pair
        else {
          i++;
          // UTF-16 encodes 0x10000-0x10FFFF by
          // subtracting 0x10000 and splitting the
          // 20 bits of 0x0-0xFFFFF into two halves
          charcode =
            0x10000 +
            (((charcode & 0x3ff) << 10) | (str.charCodeAt(i) & 0x3ff));
          utf8.push(
            0xf0 | (charcode >> 18),
            0x80 | ((charcode >> 12) & 0x3f),
            0x80 | ((charcode >> 6) & 0x3f),
            0x80 | (charcode & 0x3f)
          );
        }
      }
      return utf8;
    }
  }
};
</script>

<style scoped>
.holder {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.item {
  width: 20%;
  border: 1px solid #000;
  height: auto;
  flex: 80px 1;
  padding-left: 5px;
  padding-right: 5px;
  overflow-wrap: anywhere;
}
</style>
